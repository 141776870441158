import { v4 as uuidV4 } from 'uuid';

export enum NotificationType {
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  ApplePass = 'applePass',
  WebPush = 'webPush',
}

export enum FeatureList {
  QueueManager = 'QUEUE',
  OccupancyManager = 'OCCUPANCY',
  Bookings = 'BOOKINGS',
  CurbsidePickup = 'CURBSIDEPICKUP',
}

export enum UserStatus {
  DEFAULT = 'default',
  HAS_BEEN_CALLED = 'has_been_called',
}

// TODO: notification details cannot be accessed client-side
export interface PhoneNumberNotification {
  type: NotificationType.PhoneNumber;
  settings: {
    from: string;
    to: string;
    recipient?: string;
  };
}

export interface EmailNotification {
  type: NotificationType.Email;
  settings: {
    recipient: string;
  };
}

export interface ApplePassDevice {
  deviceLibraryIdentifier: string;
  pushToken: string;
}

export interface ApplePassNotification {
  type: NotificationType.ApplePass;
  settings: {
    passTypeIdentifier: string;
    devices: ApplePassDevice[];
  };
}

export interface WebPushNotification {
  type: NotificationType.WebPush;
  settings: {
    tokens: string[];
  };
}
export type Notification = PhoneNumberNotification | EmailNotification | ApplePassNotification | WebPushNotification;
export enum ExtraPositionStatus {
  GoToEntrance = 'goToEntrance',
}
export type PositionType = 'anonymous' | 'booking' | 'manual' | 'storePickup' | 'curbsidePickup' | 'digitalCall';

export enum CallType {
  Global = 'global',
  Store = 'store',
}

export interface CallInfo {
  id?: string;
  firstName: string;
  lastName: string;
  memberNumber: string;
  url?: string;
  usersCalled?: string[];
  type: CallType;
  agentUrl?: string;
}

export interface Station {
  id: string;
  label: string;
}

export interface PositionFeedback {
  rating: number;
  message: string;
}

export type LocationType = Pick<Location, 'locationName'>;

export interface AppointmentInfo {
  eventId?: string;
  managerId?: string;
  managerEmail?: string;
  displayName?: string;
}

export interface VirtualAppointmentInfo extends AppointmentInfo {
  isEnabled?: boolean;
  url?: string;
}

export interface Booking {
  start: string;
  end: string;
  minDate?: string;
  maxDate?: string;
  virtualAppointment?: VirtualAppointmentInfo;
  appointment?: AppointmentInfo;
}

export interface RegistrationDataItem {
  name: string;
  type: RegistrationFieldType;
  value: string | boolean | number | null;
  label?: string;
}

export type RegistrationData = RegistrationDataItem[];

export interface CustomerInfo {
  value: string;
}

export interface Position {
  id: string;
  status: PositionStatus;
  extraStatus?: ExtraPositionStatus;
  type: PositionType;
  label: string;
  priority: number;
  location: LocationType | null;
  organization: string;
  notification?: Notification;
  booking?: Booking;
  queue: string;
  createdAt: string;
  fulfilledAt?: string;
  calledAt?: string;
  completedAt?: string;
  rejectedAt?: string;
  retryCount?: number;
  externalId?: string;
  note?: string;
  verificationCode?: string;
  registrationData?: RegistrationData;
  numberInTheQueue?: number;
  tags?: Tag[];
  customerInfo?: CustomerInfo[] | string[];
  servedBy?: ServedBy;
  locale?: string;
  QRCodeCheckInPositionId?: string;
  numberOfPeople?: number;
  callInfo?: CallInfo;
  station?: Station;
  feedback?: PositionFeedback;
  waitingTime?: number;
  category?: Category;
  timeZoneOffset?: number; // It is expressed in minutes and has a sign opposite to the time zone. If I have a zone of +3, then the offset will be -180 minutes.
  curbsideOrderLink?: string;
  positionParentId?: string; // Groups all newly created tickets under the same ID after multiple transfers
  positionChildId?: string; // Links to the newly created ticket after a transfer
  _ts: number;
}

export interface ClickAndCollectPosition extends Position {
  activationNote?: string;
  note?: string;
  verificationCode?: string;
  externalId?: string;
}

export interface Tag {
  id: string;
  color?: string;
  iconUrl?: string;
  title: LocalizedField;
  titleForCopy?: LocalizedField;
  group?: LocalizedField;
}

export type CustomizableFieldType = 'text' | 'checkbox' | 'input';

export interface CustomizableField {
  key: string;
  type: CustomizableFieldType;
  data?: {
    [lng: string]: {
      value?: any;
      description?: any;
      conditions?: any[];
      icon?: string;
      required?: boolean;
    };
  };
}

interface Feedback {
  isActive: boolean;
}

interface BaseFlowModifiers {
  cancellation?: {
    enabled?: boolean;
  };
  rescheduling?: {
    enabled?: boolean;
  };
}

interface AnonymousFlowModifiers extends BaseFlowModifiers {}

interface BookingFlowModifiers extends BaseFlowModifiers {}

export interface TicketFlowModifiers {
  anonymous?: AnonymousFlowModifiers;
  booking?: BookingFlowModifiers;
}

export enum Languages {
  ar = 'ar',
  de = 'de',
  en = 'en',
  es = 'es',
  it = 'it',
  nl = 'nl',
  no = 'no',
  sv = 'sv',
  fr = 'fr',
}
export const LanguagesValues = Object.keys(Languages) as (keyof typeof Languages)[];

export type LanguagesType = keyof typeof Languages;

export type LocalizedField = {
  [lng in Languages]?: string;
};

export const buildLocalizedField = (props: LocalizedField): LocalizedField => {
  return props;
};

export enum RegistrationFieldType {
  TEXTAREA = 'textarea',
  INPUT = 'input',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  PHONE = 'phone',
  EMAIL = 'email',
  HEADER = 'header',
  PEOPLENUMBER = 'peopleNumber',
  CATEGORY = 'category',
  COVID_CERTIFICATE = 'covidCertificate',
}

export interface ValidationRules {
  required?: boolean;
  regexp?: string;
  phone?: boolean;
  email?: boolean;
  covidCertificate?: boolean;
}

export interface BaseRegistrationField {
  id: string;
  name: string;
  type: RegistrationFieldType;
  value?: string | number | boolean;
  validationRules: ValidationRules;
}

export interface RegistrationFieldInput extends BaseRegistrationField {
  type: RegistrationFieldType.INPUT;
  label?: LocalizedField;
  placeholder?: LocalizedField;
}

export interface RegistrationFieldTextarea extends BaseRegistrationField {
  type: RegistrationFieldType.TEXTAREA;
  label: LocalizedField;
}

export interface RegistrationFieldCheckbox extends BaseRegistrationField {
  type: RegistrationFieldType.CHECKBOX;
  checked: boolean;
  label: LocalizedField;
  disabled?: boolean;
}

export interface RegistrationFieldRadioOption {
  id: string;
  label: LocalizedField;
  value: string;
}

export interface RegistrationFieldRadio extends BaseRegistrationField {
  type: RegistrationFieldType.RADIO;
  label: LocalizedField;
  options: RegistrationFieldRadioOption[];
}

export interface RegistrationFieldPhone extends BaseRegistrationField {
  type: RegistrationFieldType.PHONE;
  label?: LocalizedField;
  placeholder?: LocalizedField;
}

export interface RegistrationFieldEmail extends BaseRegistrationField {
  type: RegistrationFieldType.EMAIL;
  label?: LocalizedField;
  placeholder?: LocalizedField;
}

export interface RegistrationFieldHeader extends BaseRegistrationField {
  type: RegistrationFieldType.HEADER;
  title: string;
  subtitle?: string;
}

export interface RegistrationFieldPeopleNumber extends BaseRegistrationField {
  type: RegistrationFieldType.PEOPLENUMBER;
  maxValue?: number;
  updated?: boolean;
}

export interface RegistrationFieldCategoryOption {
  id: string;
  label: LocalizedField;
  value: string;
}

export interface RegistrationFieldCategory extends BaseRegistrationField {
  type: RegistrationFieldType.CATEGORY;
  label?: LocalizedField;
  placeholder?: LocalizedField;
  options: RegistrationFieldCategoryOption[];
}

export interface RegistrationFieldCovidCertificate extends BaseRegistrationField {
  type: RegistrationFieldType.COVID_CERTIFICATE;
  label?: LocalizedField;
}

export type RegistrationField =
  | RegistrationFieldInput
  | RegistrationFieldTextarea
  | RegistrationFieldCheckbox
  | RegistrationFieldRadio
  | RegistrationFieldPhone
  | RegistrationFieldEmail
  | RegistrationFieldHeader
  | RegistrationFieldPeopleNumber
  | RegistrationFieldCategory
  | RegistrationFieldCovidCertificate;

export interface Queue {
  // Base
  id: string;
  title: string;
  displayTitle?: LocalizedField;
  description: string;
  organization: string;
  shortDisplayName: string;
  featureList: string[];
  open: boolean;
  positions: string[];
  timeslotCapacity?: number;
  logoUrl?: string;
  globalStyles?: string;
  addressLines?: string[];
  requireUserOnAuth?: boolean;
  isEmailLogin?: boolean;
  averageWaitingTime?: number;
  // Phone
  defaultPhoneNumberCountry?: string;
  phoneNumberCountries?: string[];
  phoneNumberFrom?: string;
  // Capacity
  allowEntryAboveCapacity?: boolean;
  storeCapacity?: number;
  maximumStoreCapacity?: number;
  // Info delivery
  webPushEnabled?: boolean;
  applePassEnabled?: boolean;
  smsEnabled?: boolean;
  emailEnabled?: boolean;
  preferredNotificationType?: NotificationType;
  // Curbside pickup
  curbsideLogoUrl?: string;
  // Registration feature
  registrationEnabled?: boolean;
  printingManualTicketsEnabled?: boolean;
  autoCheckOutByNextEnabled?: boolean;
  registrationFields?: RegistrationField[];
  // In Store feature
  emailsAllowed?: string[];
  isInStore?: boolean;
  textInAppCustomization: {
    // we don't want to specify keys here explicitly. They shouldn't be used directly because they are injecting into translation right after getting.
    // Translation files is the source of truth for keys here
    [lng: string]: any;
  };
  timeZone?: string;
  checkInByQRCode?: boolean;
  maxNumberOfPeoplePerWalkInTicket?: number;
  maxNumberOfPeoplePerBookingTicket?: number;
  availableTagsForQueue?: Tag[];
  customFields?: {
    position?: CustomizableField[];
    bookingForm?: CustomizableField[];
    positionBooking?: CustomizableField[];
  };
  stations?: Station[];
  feedback?: Feedback;
  booking?: {
    isBookingSmsRequired?: boolean;
    isBookingEmailRequired?: boolean;
    physicalBooking?: {
      enabled: boolean;
    };
    digitalBookingWithMsTeams: {
      enabled: boolean;
    };
    blockedDaysAhead?: number | null;
    bookingDaysCount?: number;
    registrationFields?: RegistrationField[];
  };
  ticketFlowModifiers?: TicketFlowModifiers;
  externalId?: string;
  isAutoCallEnabled?: boolean;
  isPositionWaitingTimeVisible?: boolean;
  isNumberInTheQueueVisible?: boolean;
  categories?: Category[];
  digitalCall?: {
    isEnabled: boolean;
  };
  isKeepDistanceInfoEnabled?: boolean;
  isTicketTransferEnabled?: boolean;
}

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

interface Date {
  year: number;
  month: number;
  day: number;
}

export interface LocationAddress {
  revision?: number;
  /**
   * CLDR region code of the country/region of the address.
   * This is never inferred and it is up to the user to ensure the value is correct.
   * See http://cldr.unicode.org/ and http://www.unicode.org/cldr/charts/30/supplemental/territory_information.html for details.
   * @example "CH" for Switzerland
   * */
  regionCode: string;
  /**
   * BCP-47 language code of the contents of this address (if known).
   * This is often the UI language of the input form or is expected to match one of the languages used in the address' country/region, or their transliterated equivalents.
   * This can affect formatting in certain countries, but is not critical to the correctness of the data and will never affect any validation or other non-formatting related operations.
   * If this value is not known, it should be omitted (rather than specifying a possibly incorrect default).
   * @example "zh-Hant", "ja", "ja-Latn", "en"
   * */
  languageCode?: string;
  /**
   * Postal code of the address.
   * Not all countries use or require postal codes to be present, but where they are used, they may trigger additional validation with other parts of the address (e.g. state/zip validation in the U.S.A.).
   * */
  postalCode?: string;
  sortingCode?: string;
  /**
   * Highest administrative subdivision which is used for postal addresses of a country or region.
   * For example, this can be a state, a province, an oblast, or a prefecture.
   * Specifically, for Spain this is the province and not the autonomous community (e.g. "Barcelona" and not "Catalonia").
   * Many countries don't use an administrative area in postal addresses.
   * E.g. in Switzerland this should be left unpopulated.
   */
  administrativeArea?: string;
  /**
   * Generally refers to the city/town portion of the address. Examples: US city, IT commune, UK post town.
   * In regions of the world where localities are not well defined or do not fit into this structure well, leave locality empty and use addressLines.
   */
  locality?: string;
  /** Sublocality of the address. For example, this can be neighborhoods, boroughs, districts. */
  sublocality?: string;
  /**
   * Unstructured address lines describing the lower levels of an address.
   *
   * Because values in addressLines do not have type information and may sometimes contain multiple values in a single field (e.g. "Austin, TX"), it is important that the line order is clear.
   * The order of address lines should be "envelope order" for the country/region of the address.
   * In places where this can vary (e.g. Japan), address_language is used to make it explicit (e.g. "ja" for large-to-small ordering and "ja-Latn" or "en" for small-to-large).
   * This way, the most specific line of an address can be selected based on the language.
   *
   * The minimum permitted structural representation of an address consists of a regionCode with all remaining information placed in the addressLines.
   * It would be possible to format such an address very approximately without geocoding, but no semantic reasoning could be made about any of the address components until it was at least partially resolved.
   *
   * Creating an address only containing a regionCode and addressLines, and then geocoding is the recommended way to handle completely unstructured addresses (as opposed to guessing which parts of the address should be localities or administrative areas).
   */
  addressLines: string[];
  /**
   * The recipient at the address.
   * This field may, under certain circumstances, contain multiline information.
   * For example, it might contain "care of" information.
   */
  recipients?: string[];
}

export interface Location {
  id: string;
  /** The id of the organization this location belongs to */
  organization: string;
  /** The language of the location. Set during creation and not updateable. */
  languageCode: string;
  /**
   * External identifier for this location, which must be unique inside a given account.
   * This is a means of associating the location with your own records.
   * */
  storeCode?: string;
  /**
   * Location name should reflect your business's real-world name, as used consistently on your storefront, website, and stationery, and as known to customers.
   * Any additional information, when relevant, can be included in other fields of the resource (for example, Address, Categories).
   * Don't add unnecessary information to your name (for example, prefer "Google" over "Google Inc. - Mountain View Corporate Headquarters").
   * Don't include marketing taglines, store codes, special characters, hours or closed/open status, phone numbers, website URLs, service/product information, location/address or directions, or containment information (for example, "Chase ATM in Duane Reade").
   * */
  locationName: string;
  /**
   * A phone number that connects to your individual business location as directly as possible.
   * Use a local phone number instead of a central, call center helpline number whenever possible.
   */
  primaryPhone?: string;
  /** Up to two phone numbers (mobile or landline, no fax) at which your business can be called, in addition to your primary phone number. */
  additionalPhones?: string[];
  /**
   * A precise, accurate address to describe your business location.
   * PO boxes or mailboxes located at remote locations are not acceptable.
   * At this time, you can specify a maximum of five addressLines values in the address.
   */
  address: LocationAddress;
  websiteUrl?: string;
  regularHours: {
    periods: {
      /** Indicates the day of the week this period starts on. */
      openDay: DayOfWeek;
      /** time in 24hr ISO 8601 extended format (hh:mm).
       * Valid values are 00:00-24:00, where 24:00 represents midnight at the end of the specified day field.
       * */
      openTime: string;
      /** Indicates the day of the week this period ends on. */
      closeDay: DayOfWeek;
      /**
       * time in 24hr ISO 8601 extended format (hh:mm).
       * Valid values are 00:00-24:00, where 24:00 represents midnight at the end of the specified day field.
       */
      closeTime: string;
    }[];
  };
  specialHours?: {
    specialHourPeriods: {
      /** The calendar date this special hour period starts on. */
      startDate: Date;
      /**
       * The wall time on startDate when a location opens, expressed in 24hr ISO 8601 extended format.
       * (hh:mm) Valid values are 00:00-24:00, where 24:00 represents midnight at the end of the specified day field.
       * Must be specified if isClosed is false.
       * */
      openTime: string;
      /**
       * The calendar date this special hour period ends on. If endDate field is not set, default to the date specified in startDate.
       * If set, this field must be equal to or at most 1 day after startDate.
       * */
      endDate: Date;
      /**
       * The wall time on endDate when a location closes, expressed in 24hr ISO 8601 extended format.
       * (hh:mm) Valid values are 00:00-24:00, where 24:00 represents midnight at the end of the specified day field.
       * Must be specified if isClosed is false.
       */
      closeTime: string;
      /**
       * If true, endDate, openTime, and closeTime are ignored, and the date specified in startDate is treated as the location being closed for the entire day.
       */
      isClosed: boolean;
    }[];
  };
}

export interface Occupancy {
  id: string;
  organization: string;
  counter: number;
}

export interface User {
  id: string;
  email: string;
  type: UserType;
  isAvailable: boolean;
  displayName?: string;
  organization: string;
  firstName: string;
  lastName: string;
  photo?: string;
  lastSeen?: number;
  status: UserStatus;
  tags?: Tag[];
  categories?: string[];
  serving?: string[];
}

export enum UserType {
  DEFAULT = 'default',
  SALESPERSON = 'salesperson',
  STORE = 'store',
  STORE_MANAGER = 'store_manager',
}

interface BuildUserProps {
  id?: string;
  email: string;
  type?: UserType;
  isAvailable: boolean;
  displayName?: string;
  organization: string;
  firstName: string;
  lastName: string;
  photo?: string;
  lastSeen?: number;
  status?: UserStatus;
  tags?: Tag[];
  categories?: string[];
  serving?: string[];
}

export const buildUser = (props: BuildUserProps): User => {
  const {
    id = uuidV4(),
    email,
    type = UserType.DEFAULT,
    isAvailable,
    displayName,
    organization,
    firstName,
    lastName,
    photo,
    lastSeen,
    status = UserStatus.DEFAULT,
    tags,
    categories,
    serving,
  } = props;

  const user = {
    id,
    email,
    type,
    isAvailable,
    displayName,
    organization,
    firstName,
    lastName,
    photo,
    lastSeen,
    status,
    tags,
    categories,
    serving,
  };

  return user;
};

type PublicUser = Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'displayName'>;

export interface ServedBy extends PublicUser {
  managerId?: string;
}

export interface Organization {
  id: string;
  name: string;
  supportedLocales: string[];
  tags?: Tag[];
  siteTitle?: string;
  favicon?: string;
}

export type DigitalCallMode = 'newTab' | 'pushEvent';

export interface Category {
  id: string;
  title: LocalizedField;
  labelPrefix: string;
  color: string;
  isRoot?: boolean;
  parentId?: string | null;
  children?: any;
}

interface BuildCategoryProps {
  id?: string;
  title: LocalizedField;
  labelPrefix: string;
  color: string;
}

export const buildCategory = (props: BuildCategoryProps): Category => {
  const { id = uuidV4(), title, labelPrefix, color } = props;

  const category = {
    id,
    title,
    labelPrefix,
    color,
  };

  return category;
};

export enum CovidCertificateStatus {
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum PositionStatus {
  Draft = 'draft',
  Pending = 'pending',
  Notified = 'notified',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Completed = 'completed',
  Preorder = 'preorder',
  Transferred = 'transferred',
}
